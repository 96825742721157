<template>
  <div>
    <el-row :gutter="12" style="margin: 10px 0">
      <el-col :span="24">
        <el-card>
          <div ref="lineChart" style="width: 100%; height: 400px"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'lineChartComponent',
  data() {
    return {
      WuLiaoKuChunTongJi: {
        title: {
          text: '物料库存总数据',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          left: 'left'
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '当前库存量',
            data: [],
            type: 'line',
            smooth: true
          },
        ]
      },
    };
  },
  mounted() {
    const lineChart = echarts.init(this.$refs.lineChart);

    this.$request.get('/kuckzgl/kuckzglLine')
      .then(res => {
        if (res && res.data && res.data.line) {
          this.WuLiaoKuChunTongJi.xAxis.data = res.data.line.map(v => v.date);
          this.WuLiaoKuChunTongJi.series[0].data = res.data.line.map(v => v.value);
          lineChart.setOption(this.WuLiaoKuChunTongJi);
        }
      })
      .catch(error => {
        console.error('无法加载数据:', error);
      });
  },
};
</script>

<style scoped>
</style>
