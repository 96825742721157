<template>
  <div>
    <template v-if="user.role === 'ROLE_ADMIN'||user.role === 'ROLE_USER'">
      <sysIndex></sysIndex>
    </template>
    <template v-else>
      <userIndex></userIndex>
    </template>
  </div>
</template>

<script>


import sysIndex from "../components/sysIndex.vue";//引入组件


export default {
  name: "Home",
  data() {
    return {
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  components: {
    sysIndex,

  }//注册组件
}
</script>
<style>
.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 5px 0;
}
</style>