<template>
  <div>
    <el-card class="box-card">
<!--      新版本快速入口样式代码-->
      <el-header class="header-modern">
        <div class="header-content">
          <i class="el-icon-position modern-icon"></i>
          <span class="header-text">快速入口</span>
        </div>
      </el-header>
<!--        新版本快速入口跳转代码-->
      <el-row :gutter="20" class="panel-group">
        <QuickLink v-for="(item, index) in quickLinks" :key="index" :to="item.to" :icon="item.icon" :type="item.type" :text="item.text"/>
      </el-row>
    </el-card>
    <!-- 动态饼、折、柱图 -->
  <el-row :gutter="20" class="panel-group">
<!--    <el-col :span="12">
      <lineChartComponent/>
    </el-col>
    <el-col :span="12">
      <barChartComponent/>
    </el-col>-->
    <el-col :span="12">
      <pieChartComponent/>
    </el-col>
  </el-row>

    <div style="display: flex; margin: 15px 0">
      <el-card style="width: 100%">
        <div style="margin-bottom: 15px; font-size: 20px; font-weight: bold">系统公告</div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item  v-for="(item, index) in notices" :key="item.id" :name="index + ''">
            <template slot="title">
              <h4>{{ item.title }}</h4>
            </template>
            <div>{{ item.content }}</div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div>

  </div>
</template>

<script>
 import PieChartComponent from '@/components/PieChartComponent.vue';
 import barChartComponent from '@/components/barChartComponent.vue';
import lineChartComponent from '@/components/lineChartComponent.vue';

import QuickLink from '@/components/QuickLink.vue';
export default {
  name: "sysIndex",
  components: {
    PieChartComponent,
     barChartComponent,
    lineChartComponent,

    QuickLink,
  },
  data() {
    return {
      notices: [],
      quickLinks: [
       // { to: '/im', icon: 'el-icon-service', type: 'success', text: '在线客服' },
        { to: '/shebycxx', icon: 'el-icon-warning', type: 'success', text: '设备异常信息' },
        { to: '/shebflxx', icon: 'el-icon-setting', type: 'success', text: '设备分类信息' },

        { to: '/renydtfb', icon: 'el-icon-s-custom', type: 'primary', text: '人员地图分布' },
        { to: '/quyryxx', icon: 'el-icon-data-analysis', type: 'primary', text: '区域人员信息' },
        { to: '/trajectory', icon: 'el-icon-map-location', type: 'primary', text: '会议室区域' },
        { to: '/uwb', icon: 'el-icon-view', type: 'primary', text: '电子围栏报警信息' },

         { to: '/password', icon: 'el-icon-unlock', type: 'danger', text: '修改密码' },
         { to: '/person', icon: 'el-icon-user', type: 'info', text: '个人中心' },
         { to: '/logs', icon: 'el-icon-date', type: 'danger', text: '系统日志' },
         { to: '/notice', icon: 'el-icon-message-solid', type: 'danger', text: '系统公告' },
      ],
      activeName: '', // 初始化 activeName
    };
  },
  created() {
    this.loadNotice()
  },

  methods: {
    load(){
    },
    loadNotice() {
      this.$request.get('/notice/selectUserData').then(res => {
        this.notices = res.data
      })
    },
},
  mounted() {
    this.load();
  },

}

</script>

<style scoped>
/*新版本快速入口样式代码*/
.header-modern {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #4a90e2, #cef88f);
  color: #fdfdfd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: background 0.3s;
}
.header-content {
  display: flex;
  align-items: center;
}
.modern-icon {
  font-size: 24px;
  margin-right: 10px;
}
.header-text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
}
.header-modern:hover {
  background: linear-gradient(90deg, #5cacee, #cef88f);
}

</style>