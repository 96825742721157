import { render, staticRenderFns } from "./barChartComponent.vue?vue&type=template&id=07f1ae34&scoped=true"
import script from "./barChartComponent.vue?vue&type=script&lang=js"
export * from "./barChartComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07f1ae34",
  null
  
)

export default component.exports