component
<template>

  <el-row :gutter="12" style="margin: 10px 0">
    <el-col :span="24">
      <el-card>
        <div style="width: 100%; height: 400px" id="pie"></div>
      </el-card>
    </el-col>
  </el-row>

</template>

<script>import * as echarts from 'echarts';
const ZhuYuanTongJi = {
  title: {
    text: '工牌报警总数据',
    subtext: '比例图',
    left: 'center'
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: '',
      type: 'pie',
      center: ['50%', '60%'],
      radius: '50%',
      data: [],
      label: {
        show: true,
        formatter(param) {
          return param.name + ' (' + param.percent + '%)';
        }
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
    }
  ]
}
export default {
  name: 'PieChartComponent',
  data() {
    return {}
  },
  mounted() {
    // 饼图
    let pieDom = document.getElementById('pie');
    let pieChart = echarts.init(pieDom);
    pieChart.setOption(ZhuYuanTongJi)

    this.$request.get('/uwb/uwbPie').then(res => {
      // 饼图
      ZhuYuanTongJi.series[0].data = res.data?.pie || [];
      pieChart.setOption(ZhuYuanTongJi)
    })



  },
  methods: {}
}
</script>

<style scoped>


</style>