<template>
  <el-col :span="span">
    <router-link :to="to" :class="['flex-router-link', 'card-panel', 'modern-box']">
      <i :class="icon" :style="{ fontSize: '50px' }"></i>
      <el-button :type="type" size="mini" class="button-inside-box">{{ text }}</el-button>
    </router-link>
  </el-col>
</template>

<script>
export default {
  name: "QuickLink",
  props: {
    to: String,
    icon: String,
    type: String,
    text: String,
    span: {
      type: Number,
      default: 2,
    },
  },
};
</script>

<style scoped>
.modern-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f6f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 15px;
  height: 100%;
  margin-bottom: 18px; /* 添加垂直间距 */
}
.modern-box:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 12px rgb(255, 213, 1);
}
.button-inside-box {
  margin-top: 12px;
}
</style>
