<template>
  <div>
    <el-row :gutter="12" style="margin: 10px 0">
      <el-col :span="24">
        <el-card>
          <div style="width: 100%; height: 400px" id="bar"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts';
const CaiGouTongJi = {
  title: {
    text: '采购总数据',
    left: 'center'
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    left: 'left'
  },
  xAxis: {
    type: 'category',
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: '采购数量',
      data: [],
      type: 'bar',
      smooth: true
    }
  ]
}
export default {
  name: 'barChartComponent',

  data() {
    return {

    };
  },
  mounted() {

    // 柱状图
    let barDom = document.getElementById('bar');
    let barChart = echarts.init(barDom);
    barChart.setOption(CaiGouTongJi)

    this.$request.get('/caiggl/caigglBar').then(res => {
      // 柱状图
      CaiGouTongJi.xAxis.data = res.data?.bar?.map(v => v.name) || []
      CaiGouTongJi.series[0].data = res.data?.bar?.map(v => v.value) || []
      barChart.setOption(CaiGouTongJi)
    })
  },


  methods: {
  }
}
</script>

<style scoped>

</style>
